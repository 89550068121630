.financial_main {
    width: 100%;
    position: relative;    
    overflow: hidden;
}
.inner_banner {
    width: 100%;
    position: relative;
}
.inner_banner img {
    min-height: 200px;
    object-fit: cover;
}
.inner_banner h2 {
    position: absolute;
    top: 40%;
    left: 0;
    font-family: 'Roboto-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 60px;
    line-height: 70px;
    color: #FFFFFF;
    margin: 0;      
    width: 100%;
    text-align: center;  
}
.financial_content {
    width: 100%;
    position: relative;    
}
.financial_content {
    width: 100%;
    position: relative;    
    padding: 100px 0;
}
.financial_content h2 {
    font-family: "Poppins-Medium";   
    font-style: normal;
    font-weight: 500;
    font-size: 45px;
    line-height: 54px;
    text-align: center;
    text-transform: lowercase;  
    color: #443E3E;
    margin: 0;
}
.financial_content .number {
    width: 100%;
    position: relative;  
    text-align: center; 
    margin: 40px 0;
}
.financial_content .number span {
    font-family: "Poppins-Medium";
    font-style: normal;
    font-weight: 800;
    font-size: 150px;
    line-height: 150px;
    text-transform: lowercase;
    color: #E42F67;
    text-shadow: 0px 7px 3px rgb(0 0 0 / 25%);
    display: inline-block;
}
.financial_content .number h4 {
    font-family: "Poppins-Medium";
    font-style: normal;
    font-weight: 700;
    font-size: 55px;
    line-height: 70px;
    text-align: center;
    letter-spacing: 0.07em;
    text-transform: capitalize;
    color: #443E3E;
    margin: 0;
}
.financial_partner {
    width: 100%;
    position: relative; 
    background: #F3F6FB;  
    padding: 100px 0;  
}
.financial_partner h2 {
    font-family: "Poppins-Medium";   
    font-style: normal;
    font-weight: 500;
    font-size: 45px;
    line-height: 54px;
    text-align: center;
    text-transform: lowercase;  
    color: #443E3E;
    margin: 0 0 50px;    
}
.financial_partner .list {
    width: 100%;
    position: relative;     
}
.financial_partner .list .item {
    width: 100%;
    position: relative;   
    text-align: center; 
}
.financial_partner .list .item .img {
    width: 100%;
    position: relative;  
    text-align: center;     
    min-height: 120px;
    line-height: 120px;
}
.financial_partner .list .item .img img {
    width: auto !important;
    max-width: 100% !important;    
}
.financial_partner .list .item .whiteBtn {
    color: #000;
    background: #F3F6FB;
    border: 1px solid #DAD5D5;
    box-shadow: 0px 0.706165px 2.82466px rgba(0, 0, 0, 0.25);
    border-radius: 180px;  
    min-width: 200px;
}

.financial_partner .list .item .whiteBtn:hover{
    color: #fff !important;
    background-color: crimson;
}

.financial_partner .list .item{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}

@media (max-width: 767px) {
    .inner_banner h2 {
        font-size: 34px;
        line-height: 40px;
    }
    .financial_content h2, .financial_partner h2 {
        font-size: 34px; 
        line-height: 44px;  
    }
    .financial_content, .financial_partner {
        padding: 50px 0;  
    }
    .financial_content .number {
        margin: 20px 0;    
    }
    .financial_content .number span { 
        font-size: 80px;
        line-height: 100px;    
    }
    .financial_content .number h4 {
        font-size: 40px;
        line-height: 50px;    
    }
}