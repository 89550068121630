.header {
  text-align: center;
  font-size: 26px;
  font-family: "Roboto-Bold";
  color: #443e3e;
}
.links_styles {
  color: #d0144b;
}

.subheading {
  justify-content: left;
  font-family: "Roboto-Bold";
  font-size: 16px;
  color: #443e3e;
  font-style: normal;
  font-weight: 500;
}
.termlist li {
  list-style: upper-latin;
}
.address {
  font-size: 15px;
}
