@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@300;400;500;600;700;800&display=swap');
@font-face {
  font-family: "Poppins-Bold";
  src: local("Poppins-Bold"),
   url("./assets/fonts/Poppins-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins-Medium";
  src: local("Poppins-Medium"),
   url("./assets/fonts/Poppins-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins-Regular";
  src: local("Poppins-Regular"),
   url("./assets/fonts/Poppins-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Lato-Bold";
  src: local("Lato-Bold"),
   url("./assets/fonts/Lato-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Lato-Regular";
  src: local("Lato-Regular"),
   url("./assets/fonts/Lato-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Lato-Light";
  src: local("Lato-Light"),
   url("./assets/fonts/Lato-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto-Regular";
  src: local("Roboto-Regular"),
   url("./assets/fonts/Roboto-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto-Medium";
  src: local("Roboto-Medium"),
   url("./assets/fonts/Roboto-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto-Bold";
  src: local("Roboto-Bold"),
   url("./assets/fonts/Roboto-Bold.ttf") format("truetype");
}



html, body {
  margin: 0;
  font-size: 20px;
    line-height: 1.4;
    -webkit-text-size-adjust: 100%;
  font-family: "Lato-Regular";
  color: #443E3E;
}
ul{
  padding: 0;
  margin: 0;
}
li{
  list-style-type: none;
}
a{
  text-decoration: none;
}
h2{
  font-size: 2.75rem;
  font-family: "Roboto-Bold";
}
.responsiveImg{
  width: auto;
  max-width: 100%;
}

/* @media (min-width:1366px){
  .container{
    max-width: 1330px;
  }
} */
.header{
  padding: 1rem 0;
  border-bottom: 1px solid #EEE3E3;
  position: relative;
  top: 0;
  width: 100%;
  z-index: 99;
  background: #fff;
  height: auto!important;
  overflow: hidden;
}
.mainMenu{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mainMenu .toggleMenu{
  display: none;
}
.mainMenu .logo a {
  display: inline-block;
  cursor: pointer;
}
.closeMenu{
  display: none;
}
.nav ul{
  display: flex;
  font-family: "Poppins-Regular";
  align-items: center;
}
.nav ul li{
  margin: 0 .8rem;
}
.nav ul li a{
  color: #443E3E;
  font-size: .9rem;
  cursor: pointer;
}
.nav ul li.tMenu{
  margin-left: .5rem;
}
.tMenu a{
  border-left: 1px solid #443E3E;
  padding-left: 1.5rem;
}
.indexBanner{
  padding:  0 0 0;

  height: 100vh;
}
.paytailBannerContent{
  padding-left: 3rem;
}
.paytailBannerContent h1{
  font-family: "Roboto-Bold";
  font-size: 3.5rem;
  line-height: 4.5rem;
  margin-bottom: 1rem;
}
.paytailBannerContent h1 span{
  display: block;
}
.paytailBannerContent h1 span.redColor{
  color: #D0144B;display: inline-block;
}
.paytailBannerContent p{
  font-size: 1.2rem;
  margin: 0;
}
.paytailBannerContent ul{
  display: flex;
  margin-top: 3rem;
}
.paytailBannerContent li{
  margin-right:1.3rem;
}
.paytailBannerContent h1 .text-transition {
  height: auto!important;
  overflow: hidden;    
  position: relative;
}
.flexCenter{
  display: flex;
    align-items: flex-start;
}
.indexBanner .flexCenter{
  align-items: center;
}
.paytailVide{
  width: auto;
  max-width: 100%;
}
.powerBoxMobile .paytailVide {
  max-width: 100%;
}
.video-right .paytailVide {
  max-width: 100%;
}
/*----------- Home Client Slider -------------*/
.clientSlider{
  padding-bottom: 3.5rem;
  margin-top: 1rem;
}
.clientSlider img{
  width: 100%;
}
.clientSlider li{
  padding: 0 1.5rem!important;
}
.clientSlider .rec-swipable{
  align-items: center;
}
.clientSlider li{
  position: relative;
}
.clientSlider li:after {
  position: absolute;
  top: 50%;
  right: 0;
  width: 1px;
  height: 28px;
  background: #D9D2D2;
  content: "";
  transform: translate(-50%, -50%);
}
.clientSlider .react-multiple-carousel__arrow{
  width: 28px;
    height: 28px;
    min-height: 28px;
    line-height: normal;
    border-radius: 5px;
    min-width: 28px;
    font-size: 15px;
    background: #fff;
    border: 1px solid #555353;
    box-shadow: none;
}
.clientSlider .react-multiple-carousel__arrow::before{
  font-size: 15px;
}
.clientSlider .react-multiple-carousel__arrow:hover{
  background: #D0144B!important;
  color: #fff;
  border-color: #D0144B;
}
.clientSlider .react-multiple-carousel__arrow::before{
  color: #000;
}
.clientSlider .react-multiple-carousel__arrow--left{
  left: 0;
}
.clientSlider .react-multiple-carousel__arrow--right{
  right: 0;
}
/*----------- Home Client Slider -------------*/

/*----------- Home Spend power -------------*/
.sectionPadding{
  padding: 4rem 0;
}
.powerSec{
  background: #F3F6FB;
  height: 100vh;
  display: flex;
  align-items: center;
}
.secTitle{
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  margin-bottom: 2.5rem;
}
.secTitle:after{
  position: absolute;
  bottom: -20px;
  left: auto;
  right: auto;
  content: "";
  background: url(../src/assets/images/titleBorder.png);
  width: 57px;
  height: 3px;
}
.powerBox{
  width: 37%;
}
.powerBox .list{
  filter: drop-shadow(0px 4px 4px rgba(192, 192, 192, 0.25));
  background: #fff;
  padding: .8rem 3rem .8rem 1.2rem;
  display: flex;
    align-items: center;
    margin-bottom: 1.1rem;
    height: 120px;
}
.powerBox .list .icon{
  width: 55px;
  margin-right: 1.5rem;
}
.detail span{
  color: #443E3E;
    font-size: 16px;
    font-family: "Roboto-Regular";
}
.detail p{
  font-family: "Roboto-Medium";
  font-size: .8rem;
}
.detail strong{
  font-size: 1.5rem;
}
.bSize{
  font-family: "Roboto-Bold";
  color: #443E3E;
  font-size: 16px !important;
}
.powerBoxMobile{
  width: 26%;
  text-align: center;
  position: relative;
}
.powerBoxMobile:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 1px;
  top: 0;
  left: 0;
  background-color: #f3f6fb;
}
.powerBoxMobile:after {
  position: absolute;
  content: '';
  width: 100%;
  height: 11px;
  bottom: 0;
  left: 0;
  background-color: #f3f6fb;
}
.powerSec .flexCenter{
  align-items: center;
}

/*----------- Home Spend power -------------*/

.leftSapce{
  padding-left: 2rem;
}
.rightSpace{
  padding-right: 2rem;
}

/*----------- Home Patail Way  -------------*/
.waySec{
  background: #252525;
}
.waySec h2{
  color: #fff;
  justify-content: flex-start;
  flex-direction: column;
    align-items: flex-start;
}
.waySec .col-sm-4{
  display: flex;
  align-items: center;
}
.waySec h2 span{
  display: block;
}
.wayProsess{
  width: 690px;
  max-width: 100%;
  margin-top: 4rem;
}
.wayProsess ul li{
  border: 1px solid #645D5D;
    border-radius: 10px;
    margin-bottom: 0.9rem;
    position: relative;
    padding: 1.2rem 0 1.2rem 2.2rem;
    color: #645D5D;
}
.wayProsess ul li span{
  position: absolute;
    top: 19px;
    left: -18px;
    
    font-size: .9rem;
    width: 36px;
    height: 36px;
    border: 1px solid #645D5D;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #252525;
}
.wayProsess ul li p{
  margin: 0;
    line-height: normal;
    font-size: 1.2rem;
}
.wayProsess ul li.checkBtn{
    padding: 0;
    margin: 0;
    border-radius: 0;
    margin-top: 1rem;
    border: none;
    display: inline-block;
    line-height: normal;
    padding: 0.5rem 1.5rem;
    font-size: .9rem;
    display: inline-block;
    border: 1px solid #D0144B;
    border-radius: 50px;
    color:#ffffff;
    background-color: #D0144B;
}
.wayProsess ul li.checkBtn h6{
  margin: 0;
    
}
.wayProsess ul li.checkBtn h6 a{
  color: #ffffff;
}

.wayProsess ul li{
  position: relative;
  /* left: -100%;
  opacity: 0; */
  animation-timing-function: ease;
  animation-fill-mode: forwards;
}
.wayProsess ul li.active{
  background: #E42F67;
}
.wayProsess ul li.active p{
  color: #fff;
}
.wayProsess ul li.active span{
  background: #5EB10A;
    color: #fff;
}
.sestimonialSec .appButton {
  border: 1px solid #D0144B;
  border-radius: 50px;
  color:#ffffff;
  background-color: #D0144B;
}

.sestimonialSec .appButton svg path {
  fill: #fff !important;
  stroke: #fff !important;
}
/*----------- Home Patail Way  -------------*/
.text-transition div{
  transform:translateY(0)!important;   
}

/*----------- Home Big No From Sec  -------------*/
.bigFrom{
  background: #F3F6FB url(../src/assets/images/bigFromBg.png) no-repeat;
  background-size: cover;
}
.bigUsLeftBox{
  
  display: flex;
    width: 545px;
    align-items: center;
    height: 533px;
    justify-content: flex-start;
    padding-left: 100px;
    position: relative;
}
.bigUsLeftBox .bigUsLeftBoxBg{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(../src//assets/images//bgImg.png) no-repeat;
}
.bigUsLeftBox .secTitle{
  color: #fff;
    display: flex;
    flex-direction: column;
    text-align: left;
    font-size: 3rem;
    line-height: 100px;
    margin: 0;
}
.bigUsLeftBox .secTitle span{
  display: block;
  font-size: 8rem;
}
.bigUsLeftBox .secTitle:after{
  background: url(../src/assets//images/whiteBorder.svg);
  bottom: -5px;
}
.bigMultiBox{
  display: flex;
  position: absolute;
    right: 0;
}
.bigUs{
  display: flex;
    align-items: center;
    position: relative;
}
.bigMultiBox .fromBox{
  background: #fff;
  padding: 42px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 32px;
  margin-left: 23px;
  width: 287px;
}
.bigMultiBox .fromBox .icon{
  width: 130px;
    height: 130px;
    border: 1px solid #E1DDDD;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F3F6FB;
    margin-bottom: 25px;
}
.bigMultiBox .fromBox h6{
  font-size: 1.4rem;
    color: #443E3E;
    font-family: "Roboto-Bold";
    margin: 0;
    text-align: center;
    line-height: 35px;
}
.bigMultiBox .fromBox h6 span{
  display: block
}

/*----------- Home Big No From Sec  -------------*/

/*----------- Home Deail Sec  -------------*/
.dealSec h2{
  text-align: left;
  display: flex;
  flex-direction: column;
}
.dealSec h2 span{
  display: block;
}
.dealSec .flexCenter{
  align-items: center;
}
.listContainer{
  display: flex;
    flex-direction: column;
    width: 860px;
    max-width: 100%;
    align-items: center;
    margin: 0 auto;
}
.dealSec .listContainer h2{
  align-items: center;
}
.listContainer .listBox{
  display: flex;
  align-items: center
}
.listImg{
  width: auto;
  max-width: 100%;
}
.listDetail{
  width: 455px;
  filter: drop-shadow(0px 6px 13px rgba(123, 120, 120, 0.25));
  background: #FFF9F3;
  padding: 42px 65px;
  border-radius: 15px;
  margin-left: 2rem;
  position: relative;
}
.listDetail:after{
  position: absolute;
  top: 50%;
  left: -18px;
  transform: translate(0, -50%);
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-right: 20px solid #EFE7DE;
  content: "";
}
.listDetail p{
  font-size: 1.2rem;
}
.listDetail.cardBox{
  background: #E42F67;
  margin-top: 4rem;
  margin-left: 0;
  margin-right: 2rem;
}
.listDetail.cardBox p{
  color: #fff;
}
.listDetail.cardBox:after{
  border-left: 20px solid #E42F67;
  border-right: none;
  left: auto;
  right: -18px;
}
/*----------- Home Deail Sec  -------------*/

/*----------- Home spot Sec  -------------*/
.spotSec{
  background: #F3F6FB;
}
.spotSec .flexCenter{
  align-items: center;
}
.spotSec h2{
  flex-direction: column;
    align-items: flex-start;
}
.spotSec h2 span.redColor{
  color: #E42F67;
}
.spotDetail{
  padding-right: 10rem;
}
.spotDetail p{
  font-size: 1.1rem;
}
/*----------- Home spot Sec  -------------*/

/*----------- Home Testimonial Sec  -------------*/
.sestimonialSec h2{
  flex-direction: column;
  align-items: center;
  padding-top: 4rem;
}
.sectionBothSpace{
  padding-left: 2rem;
  padding-right: 2rem;
}
.clientView{
  filter: drop-shadow(0px 8.22192px 18.4993px rgba(145, 144, 144, 0.25));
    background: #FFFFFF;
    border: 1px solid #E8E8E8;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 27px 20px;
    margin: 15px 0;
    height: calc(100% - 30px)
}
.clientView h4{
  color: #585858;
    font-family: "Poppins-Medium";
    font-size: .8rem;
    margin: 10px 0;
    text-align: center;
    line-height: 22px;
}
.clientView h4 span{
  display: block;
    color: #D0144B;
    font-family: "Poppins-Regular";
}
.clientView p{
  font-size: .9rem;
    text-align: center;
    line-height: 21px;
    margin: 0;
    position: relative;
    padding: 1rem 0;
}
.clientView p:after{
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 17px;
  height: 15px;
  background: url(../src/assets//images/text-icon.png) no-repeat;
}
.clientView p:before{
  position: absolute;
  bottom: 0;
  right: 0;
  content: "";
  width: 17px;
  height: 15px;
  background: url(../src/assets//images/text-icon.png) no-repeat;
  transform: rotate(180deg);
}
.buttonSpace{
  text-align: center;
    margin-top: 2rem;
}
.appSec{padding-top: 0;}
.appSec .flexCenter{
  align-items: center;
}
.sestimonialSec .appSec h2{
  align-items: flex-start;
  margin-bottom: 3.5rem;
}

/*----------- Home Testimonial Sec  -------------*/

/*----------- Footer Sec  -------------*/
.footer{
  background: #2E2E2E;
  color: #fff;

  padding-top: 3rem;
}
.footer h5{
  font-family: "Roboto-Bold";
  font-size: 1.1rem;
}
.footer p{
  font-size: .8rem;
  line-height: 30px;
  color:#E2E5F8
}
.footer li {
  margin-bottom: 8px;
}
.footer li p, .footer li a{
  font-size: .9rem;
  line-height: 30px;
  color: #E2E5F8;
  margin: 0;
  cursor: pointer;
}
.footer li{
  display: flex;
}
.footer .boxSpace{
  padding: 0 2rem;
}
.footer li span{text-align: left;margin-left: -10px;}
.footer li svg{
  width: 40px;
  margin-right: 5px;
  text-align: left;
}
.siteLink{
  display: flex;
    justify-content: space-between;
}
.cLogo{display: flex;}
.cLogo li{
  margin-right: 40px;
  margin-top: 30px;
}
.bottomFooter{
  display: flex;
  display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 40px;
}
.bottomFooter ul{
  display: flex;
  margin-top: 25px;
}
.bottomFooter ul li{
  margin: 0;
}
.bottomFooter ul svg{color: #fff;font-size: 25px;}
.oliveTag{
  border-top: 1px solid #4E4A4A;
    padding: 30px 0 10px;
    color: #fff;
    margin-top: 40px;
}
.companyTag{
  display: flex;
    align-items: center;
    justify-content: space-between;
}
.companyTag p{
  font-family: "Lato-Light";
  font-size: .75rem;
}
/*----------- Footer Sec  -------------*/

/*Policy-CSS-Start*/
.policy_content {
  text-align: left;
  padding: 20px 50px;
  font-size: 15px;
  text-align: left;
  line-height: 21px;
  color: #443E3E;
}
.policy_content p {
  font-size: 15px;
  text-align: left;
  line-height: 21px;
  color: #443E3E;
}
.policy_content h3 {
  font-size: 20px;
  text-align: left;
  line-height: 24px;
  color: #443E3E;
  font-family: "Roboto-Bold";
}
.policy_content ul {
  padding-left: 20px;
}
.policy_content ul li {
  font-size: 15px;
  text-align: left;
  line-height: 21px;
  color: #443E3E;  
  list-style: disc;
}
.policy_content ol {
  padding-left: 20px;
}
.policy_content ol li {
  font-size: 15px;
  text-align: left;
  line-height: 21px;
  color: #443E3E;      
  list-style: lower-roman;
}
.policy_content ul li li {
  list-style: circle;
}
.policy_content.refund ol ol li {
  list-style: lower-alpha;
}
.policy_content.refund h3 {
  font-size: 18px;
}
.policy_content.refund ol li {
  list-style: inherit;
}
.policy_content table{
  border-collapse: collapse;
}
.policy_content td, .policy_content th {
  border: 1px solid #eae5e5;
  text-align: left;
  padding: 8px;
  border-collapse: collapse;
  font-size: 15px;
}
.policy_content td a {
  text-decoration: underline;
}
/*Policy-CSS-Close*/

.waySec .rowFlex{
  display: flex;
}
.waySec .listSide{
  height: 250vh;
}
.waySec .stickyDiv{
  position: sticky;
  top:10vh
}

/* .waySec .videoSide{
  flex: 1;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding-top: 60px;
} */
.waySec .videoSide{
  height: 250vh;
    position: relative;
    width: 100%;
}
.waySec .comanV{
  display: none;
}
.waySec .comanV.active{
  display: flex;
  justify-content: center;
}
.comanV video{
  width: auto;
    max-width: 80%;
}
.waySec .videoDiv{
  position: sticky;
  top:8vh
}
.fullHeight{
  height: 100vh;
  display: flex;
  align-items: center;
}
.mobile-view, .mobileViewVideo, .viewonMobile, .lotiMobile, .qrCode, .tMenu {
  display: none;
}
.tMenu a{
  border: none;
}

/*Pagination start*/
.pagination_theme {
  width: 100%;
  position: relative;
  margin-top: 50px;
}

.pagination_theme ul {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.pagination_theme ul li {
  list-style: none;
  margin: 5px;
}

.pagination_theme ul li a {
  font-family: 'Lato-bold';
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 25px;
  color: #181717;
  width: 30px;
  height: 36px;
  display: inline-block;
  text-align: center;
  line-height: 36px;
}

.pagination_theme ul li a.active {
  background: #D0144B;
  border-radius: 5px;
  color: #fff;
  width: 40px;
}
.pagination_theme ul li a.pre-nxt {
  background: #FFFFFF;
  border: 1px solid #ACACAC;
  border-radius: 5px;
  line-height: 32px;
  width: 40px;
}
/*Pagination close*/

.vacancies .search_field {
  max-width: 500px;
  margin: 0 auto 50px;
  width: 100%;
}
.vacancies .search_field .input-group a {
  position: absolute;
  left: 0;
  top: 0;
  width: 50px;
  height: 46px;
  line-height: 46px;
  text-align: center;
  color: #898C92;
}
.vacancies .search_field .input-group .search {
  width: 100%;
  position: relative;
}
.vacancies .search_field .input-group .search input {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-transform: capitalize;
  color: #959595;
  background: #FFFFFF;
  border: 1px solid #BAB4B4;
  border-radius: 0;
  padding: 10px 10px 10px 60px;
  height: 46px;
  line-height: 44px;
  outline: none;
  box-shadow: none;
}
.vacancies .search_field .btn {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  background: #E42F67;
  border-radius: 0;
  color: #fff;
  padding: 0 15px;
  height: 46px;
  line-height: 46px;
  border: 0;
  min-width: 120px;
}
.vacancies .search_field .input-group {
  flex-wrap: inherit;
}
/*Apply Now Modal Start*/
.modal-theme .modal-title {
  font-family: "Poppins-Medium";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 60px;
  width: 100%;
  text-align: center;
}
.modal-theme .btn-close {
  position: absolute;
  right: 10px;
  top: 20px;
  border: 1px solid #d0144b;
  border-radius: 50%;
  opacity: 1;
  background: url(../src/assets/images/cross.svg);
  width: 20px;
  height: 20px;
  background-position: center;
  background-size: 20px;
  background-repeat: no-repeat;
}
.modal-theme .modal-header {
  border: 0;
}
.modal-theme .form-label {
  font-family: 'Poppins-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #433F3F;
}
.modal-theme .form-label sup {
  color: #E7244D;
}
.modal-theme .form-control, .modal-theme .form-select {
  background: #F5F6F6;
  border-radius: 5px;
  outline: none;
  box-shadow: none;
  font-size: 16px;
}

.modal-theme  .modal-footer {
  border: 0;
  justify-content: center;
}

.modal-theme .modal-footer .btn-theme {
  background: #D0144B;
  border-color: #D0144B;
  color: #fff;
  font-size: 1rem;
  height: 40px;
  line-height: 38px;
  border-radius: 2rem;
  padding: 0 25px;
}
.modal-theme .modal-footer .upload-resume {
  position: relative;
  background: #FFFFFF;
  border: 1px solid #AFAFAF;
  font-size: 1rem;
  height: 40px;
  line-height: 38px;
  border-radius: 2rem;
  color: #433F3F;
  padding: 0 25px;
}
.modal-theme .modal-footer .upload-resume .form-control[type=file] {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 40px;
  opacity: 0;
}
/*Apply Now Modal Close*/


@media (max-width:1620px){
.header{
  padding: 0.5rem 0;
}
html, body{
  font-size: 16px;
}
.bannerVideo {
  display: flex;
  justify-content: center;
}
.paytailVide {
  width: auto;
  max-width: 70%;
}
.clientSlider{
  margin-top: 3rem;
}
.powerBoxMobile .paytailVide {
  max-width: 80%;
}
.comanV video {
  width: auto;
  max-width: 63%;
}
.waySec .videoSide, .waySec .listSide{
  height: 300vh;
}
.listDetail{
  width: 420px;padding: 25px 55px
}
.listDetail.cardBox{
  margin-top: 1rem;
}
.spotImg img{
  width: 70%;
}
}


@media (max-width:1400px){
  html, body{
    font-size: 14px;
  }
  .waySec .videoSide, .waySec .listSide{
    height: 350vh;
  }
  .comanV video {
    width: auto;
    max-width: 56%;
}
  .bannerVideo{
    display: flex;
    justify-content: flex-end;
  }
  .bannerVideo video{
    width: 80%;
  }
  .sectionPadding{
    padding: 3rem 0;
  }
  .wayProsess ul li{
    padding: .8rem 0 .8rem 2.2rem;
  }
  .wayProsess{
    width: 570px;
  }
  .wayProsess ul li span{
    top: 11px;
    left: -14px;
    width: 30px;
    height: 30px;
  }
  .bigUsLeftBox{
    width: 430px;
    height: 420px;
    padding-left: 60px;
  }
  .bigMultiBox .fromBox{
    padding: 25px 10px;
    width: 247px;
    border-radius: 20px
  }
  .bigMultiBox .fromBox .icon {
    width: 100px;
    height: 100px;
  }
  .bigMultiBox .fromBox h6 {
    line-height: 28px;
    font-size: 1.2rem;
  }
  .bigUsLeftBox .bigUsLeftBoxBg{
    background-size: cover;
  }
  .bigUsLeftBox .secTitle{
    line-height: 81px;
  }
  .dealSec .listImg img{
    width: 150px;
  }
  .dealSec .bottomBox img{
    width: 290px;
  }
  .clientView p{
    font-size: .9rem;
  }
}


@media (max-width:1200px){
  html, body {
    font-size: 13px;
}
.indexBanner {
  padding: 1rem 0;
}
.bannerVideo video {
  width: 90%;
}
.wayProsess {
  width: 480px;
}
.bigMultiBox .fromBox {
  width: 215px;
}
.bigUsLeftBox .secTitle{
  line-height: 83px;
}
.bigUsLeftBox {
  width: 400px;
  height: 392px;
  padding-left: 40px;
}
.indexBanner .flexCenter, .bannerVideo {
  margin-top: 50px;
}
.powerBox .list{
  height: 95px;
}
.powerSec .flexCenter {
  margin-top: 2rem;
}

}

@media (max-width:992px){
  .logo img{
    width: 150px;
  }
  .bannerVideo video {
    width: 100%;
}  
html, body {
  font-size: 11px;
}
.powerBox .list{
  height: 88px;
}
.powerBox .list .icon img{
  width: 40px;
}
.powerBox .list .icon {
  width: 40px;}
  .wayProsess {
    width: 340px;
}  
.wayProsess ul li span{
  top: 5px;
  left: -11px;
  width: 22px;
  height: 22px;
}
.bigUsLeftBox {
  width: 310px;
  height: 304px;
  padding-left: 40px;
}
.bigMultiBox .fromBox .icon {
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
}
.bigMultiBox .fromBox .icon img{
  width: 30px;
}
.bigMultiBox .fromBox h6 {
  line-height: 18px;}
  .bigMultiBox .fromBox {
    width: 143px;
    padding: 15px 10px;
}
.bigUsLeftBox .secTitle {
  line-height: 60px;
}
.listDetail {
  width: 345px;}
.listBox.topBox .listImg img{
  width: 160px;
}
.listBox.bottomBox .listImg img{
  width: 300px;
}
.spotDetail {
  padding-right: 2rem;
}
.clientView {
  padding: 15px 15px;
    margin-bottom: 15px;
}
.clientView img{
  width: 50px;
}
.clientView h4{
  line-height: 15px;
  margin-bottom: 5px;
}
.clientView p:before, .clientView p:after{
  width: 11px;
    height: 8px;
    background-size: contain;
}
.clientView p{
  font-size: .7rem;
  padding: 0.5rem 0;
}
.footer p{
  font-size: 1rem;
  line-height: 20px;
}
.cLogo li {
  margin-right: 20px;
  margin-top: 10px;
}
.footer .boxSpace {
  padding: 0;
}
.footer li span {
  margin-left: -8px;
}
.footer li svg{
  width: 25px;
}
.footer li p, .footer li a{
  line-height: 18px;
}
.indexBanner{
  height: auto;
}
.powerSec{
  height: auto;
}
.fullHeight{
  height: auto;
}
.appImg img{
  width: 80%;
}
.appSec {
  padding: 4rem 0;
}
.bottomFooter img{
  width: 130px;
}
.bottomFooter ul svg {
 
  font-size: 18px;
}
}



@media (max-width:767px){
.indexBanner .row{
  display: flex;
    flex-direction: column;
}
.bannerVideo{
  margin-top: 10px;
  justify-content: center;
}
.bannerVideo video{
  width: 350px;
  max-width: 100%;
}
.paytailBannerContent h1{
  font-size: 3.2rem;
    line-height: normal;
}
.logo img {
  width: 130px;
}
/* .powerSec .flexCenter{
  flex-direction: column;
} */
.powerBox .list {
  height: 72px;
  padding: 0.8rem 1rem 0.8rem 1.2rem;
}
.detail strong {
  font-size: 1rem;
}
.powerBox .list .icon {
  width: 30px;
  margin-right: 1rem;
}
.powerBox .list .icon img {
  width: 30px;
}
.indexBanner .flexCenter, .bannerVideo {
    margin-top: 30px;
}
.indexBanner .flexCenter, .bannerVideo {
  margin-top: 30px;
}
.sectionPadding {
  padding: 4rem 0;
}
.secTitle{
  font-size: 2.2rem;
  margin-bottom: 2rem;
}
.secTitle:after{
  bottom: -13px;
}
.wayProsess {
  width: 310px;
}
.bigUs{
  justify-content: center;
    flex-direction: column;
}
.bigMultiBox{
  position: relative;
}
.bigUsLeftBox {
  width: 260px;
  height: 254px;
  padding-left: 40px;
  margin-bottom: 30px;
}
.bigUsLeftBox .secTitle{
  font-size: 2.5rem;
  line-height: 51px;
}
.bigUsLeftBox .secTitle span {
  display: block;
  font-size: 7rem;
}
.listDetail {
  width: 285px;
  padding: 32px 35px;
}
.listBox.bottomBox .listImg img {
  width: 210px;
}
.listBox.topBox .listImg img {
  width: 100px;
}
.spotDetail {
  padding-right: 0;
}
.clientView p {
  font-size: .9rem;
  padding: 0.5rem 0;
}
.clientView {
  padding: 15px 35px;
}
.siteLink{
  justify-content: flex-start;
  margin-top: 3rem;
}
.siteLink .footerLink{
  margin-right: 3rem;
}
.bottomFooter{
  margin-top: 10px;
}
.siteLink .footerLink {
  margin-right: 0;
  flex: 1;
}
.bottomFooter img{
  width: 130px;
}
.bottomFooter ul li {
  margin: 0 6px;
}
.mainMenu .toggleMenu{
  font-size: 29px;
    display: flex;
    margin-right: 5px;
}
.menuBg.active{
  position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #00000078;
    opacity: 0;
    z-index: -99;
}
.menuBg.active{
  opacity: 1;
  z-index: 999;
}
.menuBg ul{
  display: flex;
    flex-direction: column;
    height: 100%;
    background: #D0144B;
    align-items: flex-start;
    width: 250px;
    position: absolute;
    right: -100%;
    transition: .5s ease-out;
    
}
.menuBg.active ul{
  right: 0
} 
.nav .menuBg ul li{
  margin: 0;
    width: 100%;
}
.nav .menuBg ul li a{
  color: #fff;
    font-size: 1.2rem;
    padding: 10px 10px;
    display: block;
    border-bottom: 1px solid #fff;
}
.closeMenu {
  display: block;
  position: absolute;
  top: 0;
  right: -100%;
  background: #fff;
  color: #000;
  padding: 4px 9px;
  font-size: 22px;
  transition: .5s ease-out;
}
.menuBg.active .closeMenu{
  right: 250px;
}
.clientSlider .fAVBsF {
  padding: 0 .5rem!important;
}
.container {
  max-width: 100%;
  padding: 0 20px;
}
.header {
  padding: 2rem 0;
}
.indexBanner .flexCenter{
  order: 2;
}
.indexBanner .bannerVideo{
  order: 1;
}
.bannerVideo video{
  width: 100%;
}
.paytailBannerContent h1{
  font-size: 40px;
}
.paytailBannerContent {
  padding-left: 0;
}
.paytailBannerContent p{
  font-size: 20px;font-weight: 700;
}
.Button_whiteBtn__EwMuU{
  font-size: 14px!important;
}
.secTitle{
  font-size: 45px;
}
.powerBoxMobile, .powerBox{
  width: 100%;
}
.powerBox{
  order: 2;
}
.powerBoxMobile{
  order: 1;
}
.powerSec .flexCenter{
  flex-direction: column;
}
.powerBox .list .icon img{
  width: auto;
}
.detail span, .detail p{
  font-size: 15px;
}
.detail strong{
  font-size: 31px;
}
.powerBox .list {
  height: auto;
    padding: 25px 20px;
    width: 100%;
    margin-bottom: 20px;
}
.powerBox .list .icon {
  width: 60px;
  margin-right: 1rem;
}
.rightSpace{
  padding: 0;
}
.waySec .rowFlex {
  display: flex;
  flex-direction: column;
}
.waySec h2 {
  display: block;
  font-size: 43px;
}
.waySec h2.secTitle:after{
  transform: translate(0, -50%);
  right: auto;
  left: 39%;
}
.bigUsLeftBox {
  width: 100%;
  height: 350px;
  padding-left: 0;
  margin-bottom: 0;
  overflow: hidden;
  background: url(../src//assets/images//bgImg.png) no-repeat;
  background-size: contain;
    justify-content: center;
    align-items: flex-start;
    padding-top: 30px;
    background-position: center;
}
.bigUsLeftBox .secTitle{
  font-size: 43px;
  line-height: 75px;
}
.bigUsLeftBox .secTitle span{
  font-size: 100px;
}
.bigUsLeftBox .bigUsLeftBoxBg{
  background: none;
}
.bigMultiBox {
  position: relative;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding: 0 30px;
  margin-top: -60px;
}
.bigMultiBox .fromBox {
  width: 275px;
  padding: 35px 10px;
  margin: 0 0 20px;
}
.bigMultiBox .fromBox .icon {
  width: 110px;
  height: 110px;
  margin-bottom: 10px;
}
.bigMultiBox .fromBox .icon img {
  width: auto;
}
.bigMultiBox .fromBox h6 span {
  display: inline-block;
}
.dealSec .flexCenter {
  align-items: center;
  flex-direction: column;
}
.bigMultiBox .fromBox h6{
  font-size: 22px;
  line-height: 27px;
  font-weight: 700;
}.dealSec h2{
  display: block;
    text-align: center;
    font-size: 36px;
}
.dealSec h2 span{
  display: inline-block;
}
.dealSec .secTitle:after {
  bottom: -13px;
  left: 41%;
}
.listBox.topBox .listImg img {
  width: 80px;
}
.listDetail p {
  font-size: 16px;
  line-height: 24px;
}
.listBox.bottomBox .listImg img {
  width: 108px;
}
.listBox.topBox{
  margin-top: 40px;
}
.listContainer .listBox.bottomBox{margin-top: 50px;}
.listDetail {
  width: 220px;
  padding: 15px 25px;
  margin-top: 0;
}
.spotSec .flexCenter {
  align-items: center;
  flex-direction: column;
}
.spotSec h2{
  display: block;
}
.spotSec h2 span, .sestimonialSec h2 span, .sestimonialSec .appSec h2 span{
  display: inline-block;
}
.sestimonialSec .appSec h2{
  align-items: center;
}
.spotSec h2{
  font-size: 42px;
}
.sestimonialSec h2{
  font-size: 40px;
}
.sestimonialSec .appSec h2.secTitle:after {
  bottom: -13px;
  left: 37%;
}
.spotSec h2.secTitle:after{
  left: 38%;
}
.spotDetail p {
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 40px;
}
.spotImg img {
  width: 100%;
}
.clientView img {
  width: 100px;
}
.clientView h4 {
  line-height: 15px;
  margin-bottom: 5px;
  font-size: 14px;
}
.clientView h4 span{
  font-size: 12px;
}
.clientView p {
  font-size: 13px;
  padding: 0.5rem 0;
}
.clientView {
  padding: 30px 35px;
}
.appSec .flexCenter{
  flex-direction: column;
}
html:not(.no-js) [data-aos^=fade][data-aos^=fade].aos-animate, html:not(.no-js) [data-aos^=fade][data-aos^=fade].aos-animate, html:not(.no-js) [data-aos^=fade][data-aos^=fade].aos-animate{
  opacity: 1!important;
}
.mobile-view {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.mobile-view a{
  font-size: 18px;
}
.desktop-view{display: none;}
.appImg img {
  width: 100%;
}
.footer h5 {
  font-size: 21px;
}
.footer li p, .footer li a {
  line-height: 25px;
  font-size: 18px;
}
.bottomFooter img {
  width: 160px;
  margin-top: 20px;
}
.bottomFooter ul svg {
  font-size: 25px!important;
}
.footer li {
  margin-bottom: 12px;
}
.footer li svg {
  width: 25px;
  font-size: 16px;
}
.cLogo {
  display: flex;
  margin-bottom: 20px;
  margin-top: 20px;
  justify-content: space-between;
}
.footer p{
  font-size: 16px;

  line-height: 30px;
}
.companyTag {
  flex-direction: column;
}
.clientSlider li {
  padding: 0 .5rem!important;
}
.clientSlider .react-multiple-carousel__arrow {
  width: 22px;
  height: 22px;
  min-height: 22px;
  min-width: 22px;
}
.desktopView{
  display: none;
}
.mobileViewVideo{
  display: block;
}
.mobileViewVideo video{
  width: 150px;
}
.mobileViewVideo .vMob{
  display: none;
  text-align: center;
}
.mobileViewVideo .vMob.active{
  display: block;
}
.waySec .stickyDiv {
  position: sticky;
  top: 0;
}
.wayProsess ul li.active p{
  font-size: 14px;
}
.wayProsess ul li {
  padding: 7px 20px;
}
.wayProsess {
  width: 100%;
  margin-bottom: 20px;
}
.paytailVide{
  width: 100%;
}
.mobileToggleShow{
  display: none;
}
.mobileToggleShow.active{
  display: flex;
}
.viewonMobile{
  display: block;
  ;
}
.viewonMobile.hide {
  display: none;
}
.lotiMobile{
  display: block;
}
.bannerVideo video, .powerBoxMobile video, .paytailVide{
  display: none;
}
.qrCode{
  display: block;
}
.tMenu a {
  border-left: 1px solid #443E3E;}

}
.indexBanner, .powerSec, .fullHeight{
  overflow: hidden;
}
.career_main .row {
  margin: 0;
}
.news .react-multiple-carousel__arrow--left {
  left: 0;
}
.news .react-multiple-carousel__arrow--right {
  right: 0;
}
.news .react-multiple-carousel__arrow {
  background: #fff;
  border: 1px solid #555353;
  border-radius: 5px;
  box-shadow: none;
  font-size: 15px;
  height: 28px;
  line-height: normal;
  min-height: 28px;
  min-width: 28px;
  width: 28px;
}
.news .react-multiple-carousel__arrow:before {
  color: #000;
  font-size: 15px; 
}
.news .react-multiple-carousel__arrow:hover {
  background: #d0144b!important;
  border-color: #d0144b;
  color: #fff;
}
.news .react-multiple-carousel__arrow:hover:before {
  color: #fff;
}
/* 
@media (max-width:540px){
  .paytailBannerContent {
    padding-left: 1rem;
}
.paytailBannerContent h1 {
  font-size: 2.5rem;
  line-height: normal;
}
.powerSec .flexCenter {
  flex-direction: column;
}
.powerBox {
  width: 90%;
}
.secTitle{
  margin-bottom: 4rem;
}
.detail span{
  font-size: 1.2rem;
}
.detail p {
  font-size: 1.2rem;
}
.powerBox.rightSpace{
  padding: 0;
}
.powerBoxMobile {
  width: 50%;
  text-align: center;
  margin: 1.5rem 0;
}
.wayProsess{
  padding-left: 20px;
    margin-bottom: 2.5rem;
}
.rightSpace{
  padding: 0;
}
.waySec .col-sm-4 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.waySec .col-sm-4 .rightSpace {
  display: flex;
    justify-content: center;
}
.waySec .col-sm-4 video{
  width: 60%;
}
.bigMultiBox {
  position: relative;
  display: flex;
  flex-direction: column;
}
.bigMultiBox .fromBox{
  margin: 0 0 20px;
}
.bigUsLeftBox{
  margin-bottom: -20px;
}
.dealSec .flexCenter {
  align-items: center;
  display: flex;
  flex-direction: column;
}
.listDetail {
  width: 215px;
  padding: 12px 25px;
}
.listBox.bottomBox .listImg img {
  width: 120px;
}
.spotSec .flexCenter{
  flex-direction: column;
}
.spotImg, .appSec .appImg{
  display: flex;
    justify-content: center;
    margin-top: 20px;
}
.spotImg img{
  width: 250px;
}
.clientView h4 {
  line-height: 18px;font-size: 1.2rem;}
  .clientView p {
    font-size: 1.1rem;
    padding: 0.5rem 0;
} 
.appSec .flexCenter {
  flex-direction: column;
}
.appSec .appImg img{
  width: 250px;
}
.cLogo {
  margin-bottom: 20px;
}
.paytailBannerContent ul {
  margin-top: 2rem;
}
.footer li p, .footer li a {
  line-height: 18px;
  font-size: 1.1rem;
}
.footer h5 {
  font-size: 1.3rem;
  margin-bottom: 10px;
}
.oliveTag{
  padding: 15px 0 10px;
  margin-top: 20px;
}
.footer li svg {
  width: 15px;
}
.bottomFooter img {
  width: 100px;
}
.footer li svg {
  width: auto;
  font-size: 16px;
}
.bottomFooter ul li {
  margin: 0 4px;
}
.bottomFooter ul {
  margin-top: 7px;
}
.companyTag{
  flex-direction: column;
}

.footer li span {
  margin-left: 0;
}
}

 */
