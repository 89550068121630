@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Inter, sans-serif, "Times New Roman", Times, serif;
  font-size: 16px;
} */

.mainDiv h1 {
  /* font-size: 4rem; */
  font-weight: 700;
}

.mainDiv h1,
.mainDiv h5,
.mainDiv .center p {
  text-align: center;
}
.mainDiv b {
  font-family: sans-serif;
}

.mainDiv p ol {
  margin-top: 1rem;
}

.mainDiv p ol li {
  list-style-type: decimal;
  text-align: start;
  margin-left: 3rem;
}

.mainDiv ul li {
  list-style: disc !important;
  margin-left: 1rem;
}

.mainDiv ul li strong {
  font-weight: 900;
}

.mainDiv ul ul {
  margin-left: 1rem !important;
}

.mainDiv .links_styles {
  color: #d0144b;
}

@media (max-width: 768px) {
  .mainDiv .center img {
    width: 120px;
  }
}
