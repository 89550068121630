.about_main {
    width: 100%;
    position: relative;    
    overflow: hidden;
}
.inner_banner {
    width: 100%;
    position: relative;
}
.inner_banner img {
    min-height: 200px;
    object-fit: cover;
}
.inner_banner .content {
    position: absolute;
    top: 25%;
    left: 0;
    right: 0;
    width: 100%;
    text-align: center;
    padding: 0 20px;
}
.inner_banner .content h2 {
    font-family: "Roboto-Bold";
    font-style: normal;
    font-weight: 900;
    font-size: 100px;
    line-height: 110px;
    color: #FFFFFF;
    text-shadow: 0px 6px 4px rgba(0, 0, 0, 0.25);
}
.inner_banner .content p {
    font-family: "Poppins-Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 40px;   
    text-align: center;
    text-transform: lowercase;
    color: #FFFFFF;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    margin: 0;
    max-width: 950px;
    display: inline-block;
}
.solving_points {
    width: 100%;
    position: relative;
    background: #F3F6FB;
    padding: 100px 0;
    text-align: center;
}
.solving_points p {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    text-align: center;
    text-transform: uppercase;
    color: #443E3E;
}
.solving_points h2 {
    font-family: "Poppins-Medium";
    font-style: normal;
    font-weight: 600;
    font-size: 45px;
    line-height: 54px;
    text-align: center;
    text-transform: lowercase;
    color: #443E3E;
    margin: 0;
    max-width: 1100px;
    display: inline-block;
}
.solving_points .list {
    width: 100%;
    position: relative;
    margin: 50px 0;
}
.solving_points .list .item {
    width: 100%;
    position: relative;
    background: #FFFFFF;
    border-radius: 15px;
    text-align: center;
    padding: 20px;
    height: calc(100% - 20px);
    margin: 10px 0;
}
.solving_points .list .item .img {
    width: 110px;
    height: 110px;
    line-height: 110px;
    text-align: center;
    border-radius: 50%;
    display: inline-block;
    background: #FFF9F3;
    box-shadow: 0px 4px 4px rgba(100, 94, 94, 0.25);
}
.solving_points .list .item h4 {
    font-family: "Poppins-Bold";
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    text-align: center;
    text-transform: uppercase;
    color: #443E3E;
    margin: 20px 0;
}
.solving_points .list .item p {
    font-family: "Poppins-Regular";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    text-transform: lowercase;
    color: #443E3E;
    margin: 0;
}
.untaped_banner{
    width: 100%;
    position: relative;
}
.untaped_banner img {
    min-height: 300px;
    object-fit: cover;
}
.untaped_banner h2 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    font-family: "Poppins-Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 35px;
    line-height: 46px;
    color: #FFFFFF;
    margin: 0;      
    width: 100%;
    text-align: center; 
    max-width: 80%; 
    padding: 20px;   
    background-color: rgba(50, 49, 49, 0.3);
    border: 2px solid;
    font-size: 1.5rem;
    padding: 2rem 2rem;
}
.team {
    width: 100%;
    position: relative;
    background: #F3F6FB;
    padding: 100px 0;
}
.team h2 {
    color: #443E3E; 
    margin-bottom: 80px;   
}
.team .list { 
    margin: 0;
}
.team .list .item {
    width: 100%;
    position: relative;       
    height: calc(100% - 20px);
    margin: 10px 0; 
}
.team .list .item .img {
    width: 100%;
    position: relative;    
}
.team .list .item .content {
    position: absolute;
    bottom: 0;
    text-align: center;
    width: 100%;
    padding: 100px 20px 10px;
    background: linear-gradient(0deg, #000000 -3.38%, rgba(0, 0, 0, 0) 72%);    
}
.team .list .item .content h4 {
    font-family: "Roboto-Medium";
    font-style: normal;
    font-weight: 600;
    text-align: center;
    font-size: 22px;  
    color: #FFFFFF;      
    /* border-bottom: 1px solid #fff; */
    /* padding-bottom: 10px;  */
}
.team .list .item .content p {
    font-family: "Roboto-Regular";
    font-style: normal;
    font-weight: 400;
    text-align: center;
    font-size: 18px;  
    color: #FFFFFF;     
    margin-top: 0.5rem;
}
.team .list .item .content .alignInline .imgFluid {
    display: inline-block;
    width: 27px;
    height: 27px;
}

.team .list .item .content .alignInline {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-bottom: 1px solid #fff;
}

.news {
    width: 100%;
    position: relative;
    padding: 100px 0;
}
.news h2 {
    color: #443E3E; 
    margin-bottom: 80px;   
}
.news .news_carousel {
    padding: 0 100px;
}
.news .item {
    width: 100%;
    position: relative; 
    padding: 0 20px;
}
.news .item .img {
    width: 100%;
    position: relative;    
}
.news .item .content {
    position: relative;
    text-align: left;
    width: 100%;
    background: #F3F6FB;
    padding: 20px;
    box-shadow: 0px 3.98235px 8.76117px rgba(157, 152, 152, 0.25);
    border-radius: 0px 0px 12px 12px;  
}
.news .item .content span {
    font-family: "Lato-Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #D0144B;
    padding-bottom: 10px;
    display: block;
}
.news .item .content p {
    font-family: "Roboto-Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;  
    color: #484747; 
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    display: -webkit-box;
    overflow: hidden;     
    margin: 0;
    line-height: 26px; 
    min-height: 78px;  
}
.news .item .content a:hover p {
    color: #D0144B;    
}
@media (max-width: 767px) {
    .inner_banner .content h2{
        font-size: 34px;
        line-height: 40px;
    }
    .inner_banner .content p, .solving_points p {
        font-size: 20px;
        line-height: 28px;      
    }
    .solving_points, .team, .news {
        padding: 50px 0;  
    }
    .solving_points h2, .team h2, .news h2 {
        font-size: 34px; 
        line-height: 44px;          
    }
    .untaped_banner h2 {
        font-size: 24px; 
        line-height: 32px;  
    }
}

@media (max-width: 575px) {
    .untaped_banner h2 {
        font-size: 18px;
        line-height: 24px;
    }    
    .solving_points .list .item {
        padding: 10px;    
    }
    .solving_points .list .item h4 {
        font-size: 20px;
        margin: 10px 0;
    }
    .solving_points .list .item p {
        font-size: 15px;
    }
    .news .news_carousel {
        padding: 0 0px;
    }
    .inner_banner .content {
        top: 20%;
    }
    .inner_banner .content p {
        font-size: 16px;
        line-height: 22px;    
    }
}