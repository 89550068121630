.whiteBtn{
    color: #605F5F;
    font-size: 1rem;
    border: 1px solid #605F5F;
    padding: 0.75rem 1.5rem;
    border-radius: 2rem;
}
.whiteBtn svg{
    margin-left: 8px;
    fill:#605F5F
}
.whiteBtn svg path{
    fill:#605F5F;
    stroke: #605F5F;
}
.whiteBtn:hover{
    background: #D0144B;
    border-color: #D0144B;
    color: #fff;
}
.whiteBtn:hover svg, .whiteBtn:hover svg path{
    fill:#fff;
    stroke: #fff;
}

@media (max-width:992px){
.whiteBtn svg{
    width: 16px;
    height: 16px; 
}    
}