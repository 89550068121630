.career_main {
    width: 100%;
    position: relative;    
    overflow: hidden;
}
.inner_banner {
    width: 100%;
    position: relative;
}
.inner_banner img {
    min-height: 200px;
    object-fit: cover;
}
.inner_banner h2 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: 'Roboto-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 60px;
    line-height: 70px;
    color: #FFFFFF;
    margin: 0;      
    width: 100%;
    text-align: center;  
}
.welcome {
    width: 100%;
    position: relative;    
    padding: 4rem 0;
}
.welcome .secTitle {
    max-width: 900px;
    margin: 0 auto 2.5rem auto;
}
.welcome .welcome_img {
    width: 100%;
    position: relative;  
}
.quality {
    width: 100%;
    position: relative;    
    padding: 4rem 0;
    background: #F3F6FB;
}
.quality .secTitle {
    max-width: 900px;
    margin: 0 auto 20px auto;
}
.quality .secTitle:after {
    display: none;
}
.quality .desc {
    max-width: 800px;
    margin: 0 auto 2.5rem auto;
    font-family: 'Roboto-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    text-transform: lowercase; 
    color: #443E3E;
}
.quality .list {
    width: 100%;
    position: relative;     
}
.quality .list .quality_item {
    width: 100%;
    position: relative;  
    font-family: 'Roboto-Regular';
    font-style: normal;
    background: #FFFFFF;
    box-shadow: 0px 7px 10px rgba(188, 175, 175, 0.25);
    border-radius: 26px;   
    text-align: center;
    padding: 20px;
    height: calc(100% - 24px);
    margin: 12px 0;
}
.quality .list .quality_item .img {
    width: 130px;
    height: 130px;
    line-height: 130px;
    display: inline-block;
    text-align: center;
    border-radius: 50%;;
    position: relative;     
    background: #FFF9F3;
    box-shadow: 0px 4px 4px rgba(100, 94, 94, 0.25);
}
.quality .list .quality_item .img img {
    max-height: 70px;
}
.quality .list .quality_item h4 {
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    text-align: center;
    color: #000000;
    margin-top: 20px;
}
.quality .list .quality_item p { 
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: #443E3E;  
    margin: 0;
}
.fortheculture {
    width: 100%;
    position: relative;    
    padding: 4rem 0;
    background: #ffffff;
}
.fortheculture .secTitle {
    max-width: 900px;
    margin: 0 auto 4rem auto;
}
.fortheculture .secTitle:after {
    display: none;
}
.fortheculture .gallery img {
    margin: 12px 0;
    border-radius: 8px;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.fortheculture .gallery img:hover {
    transform: translateY(-10px);
}
.howitworks {
    width: 100%;
    position: relative;    
    padding: 4rem 0;
    background: #F3F6FB;
}
.howitworks .secTitle {
    max-width: 900px;
    margin: 0 auto 4rem auto;
}
.howitworks .secTitle:after {
    display: none;
}
.howitworks .howitworks_content {
    width: 100%;    
    display: inline-block;
    position: relative;
}
.howitworks .howitworks_content .left_side {
    width: 51%;
    float: left;
    padding: 20px 64px 20px 0px;
    position: relative;
}
.howitworks .howitworks_content:before {
    content: "";
    position: absolute;
    height: 100%;
    left:  50%;
    width: 2px;    
    border-left: 2px dashed #000; 
    top: 0px;
}
.howitworks .howitworks_content .common_box {
    width: 100%;
    float: left;
    padding: 30px;
    position: relative;
    filter: drop-shadow(0px 6px 13px rgba(123, 120, 120, 0.25));    
    z-index: 2;
}
.howitworks .howitworks_content .left_side .common_box {
    background: #D0144B;
    border-radius: 8px;
}
.howitworks .howitworks_content .common_box.left_active:before {
    content: "";
    border-left: 15px dashed #D0144B;
    border-bottom: 15px solid transparent;
    border-top: 15px solid transparent;
    border-right: 0px solid transparent;
    position: absolute;
    right: -15px;
    top: 50px;
}
.howitworks .howitworks_content .common_box.right_active:before {
    content: "";
    border-right: 15px dashed #FFF9F3;
    border-bottom: 15px solid transparent;
    border-top: 15px solid transparent;
    border-left: 0px solid transparent;
    position: absolute;
    left: -15px;
    top: 55px;
}
.howitworks .howitworks_content .right_side {
    width: 51%;
    float: right;
    padding: 20px 0px 20px 64px;
    position: relative;
}
.howitworks .howitworks_content .right_side .common_box {
    background: #FFF9F3;
    border-radius: 8px;
}
.howitworks .howitworks_content .common_box p {
    font-family: 'Lato-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;  
    margin: 0;  
}
.howitworks .howitworks_content .left_side p {
    color: #FFFFFF;    
}
.howitworks .howitworks_content .right_side p {
    color: #605F5F;
}
.howitworks .howitworks_content .common_box.right_active .step_number {
    position: absolute;
    left: -76px;
    z-index: 1;
    top: 50px;
}
.howitworks .howitworks_content .common_box.left_active .step_number  {
    position: absolute;
    right: -76px;
    z-index: 1;
    top: 50px;
}
.howitworks .howitworks_content .common_box .step_number {
    background: #f3f6fb;
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 42px;
    border-radius: 50%;
    color: #090909;
    border: 4px solid #EFE7DE;
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 700;
    font-size: 21px;
}
.vacancies {
    width: 100%;
    position: relative;    
    padding: 4rem 0;
    background: #ffffff;
}
.vacancies .secTitle {
    max-width: 900px;
    margin: 0 auto 2.5rem auto;
}
.vacancies .secTitle:after {
    display: none;
}
.vacancies .list .item {
    width: 100%;
    position: relative;
    background: #F9F9F9;
    border: 1px solid #E7E3E3;
    padding: 20px;
    height: calc(100% - 24px);
    margin: 12px 0;    
}
.vacancies .list .item .title {
    font-family: 'Roboto-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 32px;
    text-transform: capitalize;
    color: #000000;    
}
.vacancies .list .item p {
    font-family: 'Lato-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 29px;
    color: #6D6A6A; 
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    min-height: 87px;   
}
.vacancies .list .item .action {
    width: 100%;
}
.vacancies .list .item .action .whiteBtn {
    background: #D0144B;
    border-color: #D0144B;
    color: #fff;
}
.vacancies .list .item .action .grayBtn {
    background: #5A5656;
    border-color: #5A5656;
    color: #fff;
    margin-right: 10px;
}
.job_desc {
    width: 100%;
}
.job_desc .top {
    width: 100%;
    padding: 50px 0;
    background: #F5F5F5;
}
.job_desc .top h2 {
    margin: 0;
}
.job_desc .detail {
    padding: 50px 0;
    width: 100%;
}
.job_desc .detail h2 {
    width: 100%;   
    font-style: normal;
    font-size: 45px;  
    color: #443E3E;  
    margin-bottom: 40px;
}
.job_desc .detail .top_action {
    width: 100%;  
    margin-bottom: 50px; 
}
.job_desc .detail .top_action ul {
    width: 100%;   
    display: flex;   
    justify-content: space-between;
    align-items: center;    
    background: #F0EFEF;
    padding: 10px 20px;
}
.job_desc .detail .top_action ul li {
    display: flex;
    align-items: center;
}
.job_desc .detail .top_action ul li span {
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 35px;
    color: #262626;
    padding-left: 10px;    
}
.job_desc .detail .top_action ul li span strong {
    font-weight: 500;
    font-family: "Poppins-Medium";    
}
.job_desc .detail .top_action p {
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: #000000;
    margin: 20px 0 0;
}
.job_desc .detail .key_points {
    width: 100%;
    margin-bottom: 30px;
}
.job_desc .detail .key_points h2 {
    font-size: 35px;   
    color: #443E3E;
    margin-bottom: 20px;
}
.job_desc .detail .key_points ul {
    width: 100%;   
}
.job_desc .detail .key_points ul li {
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #000000;      
    margin: 10px 0;
    padding: 10px 20px 10px 60px; 
    position: relative;
}
.job_desc .detail .key_points.requirements ul li {
    background: #F3F6FB;
}
.job_desc .detail .key_points ul li:before {
    position: absolute;
    content: ">";
    top: 16px;
    left: 20px;
    width: 25px;
    height: 25px;
    line-height: 26px;
    border-radius: 50%;
    background: #E7244D;
    color: #fff;
    text-align: center; 
}
.job_desc .whiteBtn {
    background: #D0144B;
    border-color: #D0144B;
    color: #fff;   
} 
@media (max-width:1199px){
    .howitworks .howitworks_content .common_box.left_active .step_number  {
        right: -80px;
    }
}
@media (max-width:991px){
    .job_desc .detail .top_action ul {
        display: block;
    }
    .job_desc .detail .top_action ul li {
        width: 100%;
        margin: 15px 0;
    }
    .job_desc .detail .top_action ul li span {
        font-size: 16px;
        line-height: 26px;
    }
}
@media (max-width:767px){
    .inner_banner h2 {
        font-size: 34px;
        line-height: 40px;    
    }
    .fortheculture .gallery .text_end, .fortheculture .gallery .text_start {
        text-align: center !important;
    }
    .fortheculture .gallery img {
        margin: 12px;
    }
    .career_main .secTitle, .job_desc .detail h2 {
        font-size: 34px;
    }
    .job_desc .detail .key_points ul li {
        font-size: 16px;
        line-height: 22px;
    }
    .quality .list .quality_item h4 {
        font-size: 22px;
        line-height: 28px;
    }
    .quality .list .quality_item p, .job_desc .detail .top_action p {
        font-size: 16px;
        line-height: 22px;
    }
}
@media (max-width:575px){
    .howitworks .howitworks_content:before   {
        display: none;
    }
    .howitworks .howitworks_content .left_side {
        width: 100%;
        padding: 30px 0;
    }
    .howitworks .howitworks_content .right_side {
        width: 100%;
        padding: 30px 0;
    }
    .howitworks .howitworks_content .common_box.left_active:before {
        top: inherit;
        bottom: -20px;
        left: 50%;
        right: inherit;
        transform: rotate(90deg);
    }
    .howitworks .howitworks_content .common_box.right_active:before {
        bottom: -20px;
        top: inherit;
        left: 50%;
        transform: rotate(270deg);
    }
    .howitworks .howitworks_content .common_box.right_active .step_number {
        left: calc(50% - 25px);
        top: -25px;     
    }
    .howitworks .howitworks_content .common_box.left_active .step_number  {
        right: calc(50% - 25px);
        top: -25px;
    }
}