.blog_main {
    background: #F5F5F5;
    padding: 4rem 0;
}
.blog_main h2 {
    flex-direction: column;
    align-items: center;
}
.blog_main .blog_list {
    width: 100%;
    position: relative;
    padding-top: 2rem;
}
.blog_main .blog_list .blog_item {
    width: 100%;
    position: relative;
    height: calc(100% - 30px);
    margin: 15px 0;  
}
.blog_main .blog_list .blog_item .blog_img {
    width: 100%;
    position: relative;
    border-radius: 10px 10px 0 0;
    overflow: hidden;     
}
.blog_main .blog_list .blog_item .blog_img img {
    width: 100%;
}
.blog_main .blog_list .blog_item .blog_detail {
    position: relative;
    background-color: #fff;
    padding: 20px;
    border-radius: 0 0 10px 10px;
    box-shadow: 0px 3.65636px 10.9691px rgb(82 76 76 / 25%);
}
.blog_main .blog_list .blog_item .blog_detail .date {
    font-family: 'Roboto-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #D0144B;   
    -webkit-box-orient: inherit;
    -webkit-line-clamp: inherit;
    overflow: inherit; 
    min-height: inherit;
}
.blog_main .blog_list .blog_item .blog_detail .title {
    font-family: 'Roboto-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #000000;    
    padding: 0;    
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    min-height: 56px;    
    margin-bottom: 15px;
}
.blog_main .blog_list .blog_item .blog_detail p {
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #000000;  
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;    
    min-height: 78px;
}
.blog_main .blog_list .blog_item .blog_detail .action {
    width: 100%;
    border-top: 1px solid #DFDFDF;
    padding: 15px 0 0;
}
.blog_main .blog_list .blog_item .blog_detail .action a {
    font-family: 'Lato-Regular';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    text-transform: capitalize;  
    color: #4D4D4F;
    cursor: pointer;
}
.blog_main .blog_list .blog_item .blog_detail .action a img {
    padding-left: 5px;
}
.blog_main .blog_list .blog_item .blog_detail .action a:hover {
    color: #D0144B;   
}
.blog_detail {
    background: #F5F5F5;
    padding: 4rem 0;
}
.blog_detail .blog_detail_inner {
    background: #FFFFFF;
    padding: 20px 50px;
}
.blog_detail .blog_detail_inner .secTitle {   
    font-size: 45px;
    color: #000000;   
}
.blog_detail .blog_detail_inner .img {
    margin-bottom: 50px;
}
.blog_detail .blog_detail_inner .img img {
    border-radius: 10px 10px 0px 0px;   
}
.blog_detail .blog_detail_inner .content_inner {
    width: 100%;
    position: relative;
    padding-left: 150px;
}
.blog_detail .blog_detail_inner .content_inner .left {
    position: absolute;
    left: 0;
    width: 150px;
    top: 0;
    padding: 0 50px;
    text-align: center;
}
.blog_detail .blog_detail_inner .content_inner .left .date_month {
    width: 100%;
    position: relative;
    text-align: center;
}
.blog_detail .blog_detail_inner .content_inner .left .date_month:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: #D0144B;
    border-radius: 6px;
}
.blog_detail .blog_detail_inner .content_inner .left .date {
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: 700;
    font-size: 46px;
    line-height: 46px;
    color: #000000;
}
.blog_detail .blog_detail_inner .content_inner .left .month {
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    color: #443E3E;
}
.blog_detail .blog_detail_inner .content_inner .left .social {
    width: 100%;
    text-align: center;
    margin: 50px 0;
    padding: 0;
}
.blog_detail .blog_detail_inner .content_inner .left .social li {
    width: 100%;
    position: relative;
    margin: 10px 0;
}
.blog_detail .blog_detail_inner .content_inner .left .social li a {
    background: #FFFFFF;
    border: 1px solid #B6B6B6;
    width: 40px;
    height: 40px;
    line-height: 38px;
    border-radius: 50%;
    display: inline-block;
    color: #2E2E2E;
}
.blog_detail .blog_detail_inner .content_inner .left .social li a:hover {
    color: #D0144B;   
}
.blog_detail .blog_detail_inner .content_inner .content {
    width: 100%;
    position: relative;
}
.blog_detail .blog_detail_inner .content_inner .content h2 { 
    font-size: 45px;
    color: #000000;    
}
.blog_detail .blog_detail_inner .content_inner .content p {
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;   
    color: #000000;
}
.blog_detail .blog_detail_inner .content_inner .content h3 {
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    color: #000000;   
    margin: 30px 0 10px;
}

@media (max-width:991px){ 
    .blog_detail .blog_detail_inner {
        padding: 20px 20px;
    }
    .blog_detail .blog_detail_inner .content_inner .content h2 { 
        font-size: 34px;
    } 
     .blog_detail .blog_detail_inner .content_inner .content h3 {
        font-size: 30px;
    }
}
@media (max-width:991px){ 
    .blog_detail .blog_detail_inner .content_inner {
        padding-left: 0px;
    }
    .blog_detail .blog_detail_inner .content_inner .left {
        padding: 0;
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;    
        margin-bottom: 30px;
    }
    .blog_detail .blog_detail_inner .content_inner .left .date_month {
        width: 50px;
    }
    .blog_detail .blog_detail_inner .content_inner .left .social {
        display: flex;
        align-items: center;
        justify-content: flex-end;    
        margin: 0;
    }
    .blog_detail .blog_detail_inner .content_inner .left .social li {
        width: auto;
        margin: 0 10px;    
    }
}
@media (max-width:575px){ 
    .blog_detail .blog_detail_inner {
        padding: 20px 20px;
    }
    .blog_detail .blog_detail_inner .content_inner .content h2 { 
        font-size: 28px;
    } 
     .blog_detail .blog_detail_inner .content_inner .content h3 {
        font-size: 22px;
    }
}